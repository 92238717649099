import React from 'react';
import Sectitle from '../Title/Sectitle';
import ErpServiceitem from './ErpServiceitem';

const ErpService =()=> {
    return(
        <section className="erp_service_area sec_pad">
            <div className="container">
                <Sectitle sClass="hosting_title erp_title text-center" Title='Reliable, Powerful & Economical' TitleP='Keeping a detailed record of your marketing efforts is one of the best ways to increase your ROI'/>
                <div className="row">
                    <ErpServiceitem itemClass="pr_70" sTitle="Fraud Prevention" pText="Protect your brand and combat fraud proactively with our proprietary algorithm. Take immediate actions on abnormal campaign behavior and spend smartly." icon="erp_icon1.png"/>
                    <ErpServiceitem itemClass="pr_70 pl_10" sTitle="All In One Dashboard" pText="Monitor all marketing data and business metrics in one place. Save time reviewing health of your business and make better decisions." icon="erp_icon2.png"/>
                    <ErpServiceitem itemClass="pl_70" sTitle="Amazing Support" pText=" Find out how we can help by visiting our global support page. 71% of customers believe that “valuing their time” is the most important thing a company can do to provide good customer service." icon="erp_icon3.png"/>
                    <ErpServiceitem itemClass="pr_70" sTitle="Multiple Payout Models" pText="Multiple payout models including CPI, CPA, CPS & CPC. Easily select the right performance measurement and deploy multiple business models on one platform." icon="erp_icon4.png"/>
                    <ErpServiceitem itemClass="pl_10" sTitle="Real-Time Data" pText="Make instant decisions with real-time reporting system. Evaluate performance of every offer and affiliate by length of minute." icon="erp_icon5.png"/>
                    <ErpServiceitem itemClass="pl_70" sTitle="Targeting Control" pText="Accurately find the right users by multi-layered targeting settings, from targeting geographies to specific devices and operating systems. Campaigns are optimized more effectively." icon="erp_icon6.png"/>
                </div>
            </div>
        </section>
    )
}

export default ErpService;