import React from 'react';
import ErpFeaturesitem from './ErpFeaturesitem';
import ErpIconitem from './ErpIconitem';


const ErpFeatures =()=>{
    return(
        <section className="erp_features_area_two sec_pad">
            <div className="container">
                <ErpFeaturesitem rowClass="align-items-center flex-row-reverse" subtext1="Alert & Notification" subtext2="Block Unwanted Partners" heading="Advanced Reporting" para="With Adsgraphy you can easily prepare the custom reports that help you in decision making." image="Advtargetinggif.gif"/>
                <ErpFeaturesitem rowClass="align-items-center" image="Advtargetinggif.gif" subtext1="Intelligent Redirect" subtext2="Targeting Control" heading="Advanced Targeting" para="Adsgraphy Advanced Targeting assures that the offers and campaigns are performed at the optimum cost." roundClass="red"/>
                <ErpFeaturesitem rowClass="align-items-center flex-row-reverse" subtext1="Brand Customization" subtext2="Flexible Team Management" heading="Custom Payout Goal" para="Adsgraphy allows you to create the rules for a custom payout based on GEO, affiliates, device and OS." image="Advtargetinggif.gif" roundClass="green"/>
            </div>
        </section>
    )
}
export default ErpFeatures;