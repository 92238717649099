import React from "react";
const EventPrice = () => {
  return (
    <section className="event_price_area sec_pad">
      <div className="container">
        <div className="hosting_title security_title text-center">
          <h1>
            <h1>15-day Free Trial.</h1>
          </h1>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-4 col-sm-6">
            <div className="analytices_price_item event_price_item wow fadeInUp">
              <div className="p_head">
                <h3>Start UP </h3>
                <div className="rate">$50.00</div>
              </div>
              <ul className="list-unstyled p_body">
                <li>
                Unlimited Offers & Users <i className="ti-check"></i>
                </li>
                <li>
                500k Clicks Per Month <i className="ti-check"></i>
                </li>
                <li>
                Impressions 0.01 USD per 1000 <i className="ti-check"></i>
                </li>
                <li>
                Custom Platform Domain<i className="ti-check"></i>
                </li>
                <li>
                Email Support<i className="ti-check"></i>
                </li>
                <li>
                Multiple Tracking Domains<i className="ti-close"></i>
                </li>
                <li>
                APIs Access<i className="ti-close"></i>
                </li>
                <li>
                Intelligent Smartlink<i className="ti-close"></i>
                </li>
                <li>
                Fraud Detection<i className="ti-close"></i>
                </li>
              </ul>
              <div className="text-center">
                <a href="/#" className="event_btn event_btn_two btn_hover">
                  Choose This Plan
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div
              className="analytices_price_item event_price_item active wow fadeInUp"
              data-wow-delay="0.2s"
            >
              <div className="p_head">
                <h3>Professional</h3>
                <h6 className="tag">
                  <i className="icon_ribbon_alt"></i> Most popular
                </h6>
                <div className="rate">$100.00</div>
              </div>
              <ul className="list-unstyled p_body">
                <li>
                Unlimited Offers & Users <i className="ti-check"></i>
                </li>
                <li>
                10k Conversions Per Month<i className="ti-check"></i>
                </li>
                <li>
                2 Million Impressions Included <i className="ti-check"></i>
                </li>
                <li>
                SSL For Platform Domain & Tracking Domain <i className="ti-check"></i>
                </li>
                <li>
                Custom Platform Domain<i className="ti-check"></i>
                </li>
                <li>
                Custom Tracking Domain<i className="ti-check"></i>
                </li>
                <li>
                Multiple Tracking Domains<i className="ti-check"></i>
                </li>
                <li>
                Email/Skype/Call Support<i className="ti-check"></i>
                </li>
                <li>
                APIs Access<i className="ti-check"></i>
                </li>
                <li>
                Intelligent Smartlink<i className="ti-check"></i>
                </li>
                <li>
                Fraud Detection<i className="ti-close"></i>
                </li>
              </ul>
              <div className="text-center">
                <a href="/#" className="event_btn btn_hover">
                Choose This Plan
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div
              className="analytices_price_item event_price_item wow fadeInUp"
              data-wow-delay="0.4s"
            >
              <div className="p_head">
                <h3>Enterprise</h3>
                <div className="rate">$250.00</div>
              </div>
              <ul className="list-unstyled p_body">
                <li>
                Unlimited Offers & Users <i className="ti-check"></i>
                </li>
                <li>
                40k Conversions Per Month<i className="ti-check"></i>
                </li>
                <li>
                15 Million Impressions Included <i className="ti-check"></i>
                </li>
                <li>
                SSL For Platform Domain & Tracking Domain<i className="ti-check"></i>
                </li>
                <li>
                Custom Platform Domain<i className="ti-check"></i>
                </li>
                <li>
                Custom Tracking Domain<i className="ti-check"></i>
                </li>
                <li>
                Multiple Tracking Domains<i className="ti-check"></i>
                </li>
                <li>
                Email/Skype/Call Support<i className="ti-check"></i>
                </li>
                <li>
                APIs Access<i className="ti-check"></i>
                </li>
                <li>
                Intelligent Smartlink<i className="ti-check"></i>
                </li>
                <li>
                Fraud Detection<i className="ti-check"></i>
                </li>
              </ul>
              <div className="text-center">
                <a href="/#" className="event_btn event_btn_two btn_hover">
                Choose This Plan
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default EventPrice;
