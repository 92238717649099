import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import Contacts from '../components/Contacts';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import FooterErp from '../components/Footer/FooterErp';

const About = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Contac Us" Pdescription="Connect with Us on our Skype Support and start Your new Network Journey"/>
            <Contacts/>
            <FooterErp fClass="h_footer_dark_two" FooterData={FooterData}/>

            {/* <FooterTwo FooterData={FooterData}/> */}
        </div>
    )
}
export default About;