import React from 'react';
import HostingFeaturesItem from './HostingFeaturesItem';

const HostingFeaturesTwo =()=>{
    return(
        <section className="hosting_features_area">
            <div className="container">
                <HostingFeaturesItem rowClass="flex-row-reverse" hImage="business2.png" hTitle="Solutions for Your small business" hPragraph="Don't get shirty with me owt to do with me arse down the pub have it car boot happy days no biggie bevvy only a quid I, in my flat the little rotter cheeky.!" 
                icon="icon9.png" icon2="icon10.png" text="Reseller Hosting" text2="Wordpress Hosting" tdescription="Hunky dory barney fanny around up the duff no biggie." tdescription2="Hunky dory barney fanny around up the duff no biggie."/>
            </div>
        </section>
    )
}

export default HostingFeaturesTwo;