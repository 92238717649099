import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import SequrityPrice from '../components/Features/SecurityPrice';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import EventPrice from "../components/EventPrice";
import EventFact from '../components/EventFact';
import FooterErp from '../components/Footer/FooterErp';


const Price = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Pricing Plan" Pdescription="SignUp Today and get 30 days free trial. You won't be charged until the trial period ends."/>
            <EventPrice />
            <EventFact />
            <FooterErp fClass="h_footer_dark_two" FooterData={FooterData}/>

            {/* <FooterTwo FooterData={FooterData}/> */}
        </div>
    )
}
export default Price;